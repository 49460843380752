import store from '@/store'
import { updateProperty } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    type: 'autocomplete',
    autoCompleteType: 'contact',
    name: 'notary_of_buyers',
    label: 'Notaris koper',
    placeholder: 'Selecteer een contact'
  }
]

const actions = {
  load (propertyId) {
    const property = store.getters['properties/getPropertyById'](propertyId)
    // Property data is already loaded by the layout, no need to call API again.
    const { notary_of_buyers } = { ...property }
    return { notary_of_buyers }
  },
  async submit (propertyId, data) {
    try {
      const { notary_of_buyers } = data
      const payload = { notary_of_buyers: notary_of_buyers?.id || null }
      await updateProperty(propertyId, payload)
      await store.dispatch('properties/loadProperty', propertyId)
      successModal('De notaris is succesvol aangepast')
    } catch (error) {
      errorModal('Fout bij opslaan van notaris koper, probeer het opnieuw.')
      throw error
    }
  }
}

export default {
  heading: 'Notaris koper',
  schema,
  actions
}
